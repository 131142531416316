<template>
  <v-container fluid>
    <v-card class="secondary my-3">
      <v-row class="px-3 d-flex justify-center">
        <v-col cols="12">
          <h1
            style="font-family: poppins; font-size: 1.4rem"
            class="colorText--text mt-4"
          >
            Bem vindo a nossa fila digital!
          </h1>
          <p style="font-family: poppins" class="colorText--text mt-1">
            Insira seus dados abaixo para entrar em nossa
            <strong>fila digital</strong>, e aguarde, enviaremos
            <strong>SMS</strong> para o seu número assim que sua vez chegar!
          </p>
        </v-col>
        <v-col>
          <v-text-field
            label="Nome"
            color="success"
            filled
            dark
            prepend-inner-icon="mdi-account"
            v-model="user.nome"
          >
          </v-text-field>
          <v-text-field
            label="Celular"
            color="success"
            filled
            dark
            prepend-inner-icon="mdi-cellphone"
            v-model="user.telefone"
            v-mask="'(##) #####-####'"
          >
          </v-text-field>
          <v-select
            label="Selecione a loja que deseja entrar na fila"
            color="success"
            filled
            dark
            prepend-inner-icon="mdi-store"
            :items="items"
            v-model="user.loja"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="6" class="mb-3">
          <v-btn @click="entrarFila" large block class="success mt-n5"
            >Entrar na fila</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "fila",
  data: () => ({
    user: {
      nome: "",
      telefone: "",
      loja: "",
    },
    items: ["Rio Branco", "Vila Velha", "Ilha de St Maria"],
  }),
  methods: {
    entrarFila() {
      this.$http.post(this.$filaRoute, this.user);
      this.user = {
        nome: "",
        telefone: "",
        loja: "",
      };
    },
  },
};
</script>
