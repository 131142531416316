import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({theme: {
    themes: {
      light: {
        primary: '#11171D',
        secondary: '#202A37',
        colorText: '#BDBDBD',
        success: '#16C177',
        error: '#C51E1E'
      },
    },
  },
});
