<template id="app">
  <v-app>
    <v-app-bar app color="secondary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="./assets/vestementaIcon.svg"
          transition="scale-transition"
          width="40"
        />
      </div>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <v-footer class="primary" padless>
      <v-col class="text-center success--text" cols="12">
        {{ new Date().getFullYear() }} —
        <strong class="success--text">VesteMenta Uniformes</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>
  #app {
    background: #11171D;
  }
</style>
