import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import VueMask from 'v-mask'
Vue.use(VueMask);
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

// Vue.use(new VueSocketIO({
//     debug: true,
//     connection: SocketIO('ws://vestementa-dash.herokuapp.com:4004'), //options object is Optional
//     vuex: {
//       store,
//       actionPrefix: "SOCKET_",
//       mutationPrefix: "SOCKET_"
//     }
//   })
// );

Vue.prototype.$filaRoute = 'https://vestementa-dash.herokuapp.com/api/v1/fila/'
// Vue.prototype.$filaRoute = 'http://localhost:3000/api/v1/fila/'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
